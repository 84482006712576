@charset "UTF-8";

@function unicode($str) {
	@return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"");
}

// 2. Loading custom variables
@import "bsCustom";
@import "faCustom";
@import "slCustom";

//@import "~bootstrap/scss/mixins/banner";
//@include bsBanner("");

// 3. Loading of required bootstrap classes
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// 4. Custom Utilities
$utilities: map-merge(
	$utilities,
	(
		"bg-opacity" : map-merge(
			map-get($utilities, "bg-opacity"),
			(
				values: map-merge(
					map-get(map-get($utilities, "bg-opacity"), "values"),
					(5: .05, 10: .1),
				),
			),
		),
		"width": map-merge(
			map-get($utilities, "width"),
			(
				values: map-merge(
					map-get(map-get($utilities, "width"), "values"),
					(33: 33%, 67: 67%),
				),
			),
		),
	),
);

// 4. Loading bootstrap components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
//@import "~bootstrap/scss/placeholders";
//@import "~bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utililies.scss`
@import "~bootstrap/scss/utilities/api";

// 6. FontAwesome
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
//@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import "@fortawesome/fontawesome-pro/scss/brands";

// 6. SimpleLightBox
@import "~simplelightbox/src/simple-lightbox";

// 7. Custom modules
@import "pushNotification";

// 8. Custom code
@font-face {
	font-family: 'Open Sans';
	src: local('Open Sans'),
	url('../Fonts/OpenSans-Regular.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: local('Open Sans Bold'),
	url('../Fonts/OpenSans-Bold.woff2') format('woff2');
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: local('Open Sans Light'),
	url('../Fonts/OpenSans-Light.woff2') format('woff2');
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'LPBambus';
	src: url('../Fonts/LPBambus-Regular.otf') format('truetype');
}

@font-face {
	font-family: 'LPBambus';
	src: url('../Fonts/LPBambus-Light.otf') format('truetype');
	font-weight: 300;
	font-display: swap;
}

:root {
	--fb-header-height: 74px;
}

.of-cover {
	object-fit: cover;
	-o-object-fit: cover;
}

body {
	font-family: 'Open Sans', sans-serif;
}

#header {
	position: fixed;
	z-index: 2;
	background-color: $white;

	@include media-breakpoint-down(lg) {
		border-bottom: 1px solid $body-color;
	}

	@include media-breakpoint-up(lg) {
		position: static;
	}
}

main {
	min-height: calc(100vh - 310px);

	@include media-breakpoint-down(lg) {
		padding-top: var(--fb-header-height);
	}
}

h1, .h1 {
	color: $primary;
	font-weight: bold;
}

.post-content h1, .post-content .h1,
.post-content h2, .post-content .h2,
.post-content h3, .post-content .h3,
.post-content h4, .post-content .h4,
.post-content h5, .post-content .h5,
.post-content h6, .post-content .h6 {
	margin-bottom: 2rem;
}

.post-content h2, .post-content .h2 {
	font-weight: bold;
}

.post-content a,
.lexikon-content a {
	text-decoration: underline;
}

p + ul,
p + ol {
	margin-top: -1rem;
}

a,
a:hover {
	color: #535353;
}

img {
	aspect-ratio: attr(width) / attr(height);
}

figcaption {
	color: $gray-650;
}

.anchor {
	margin-top: calc(-1 * calc(var(--fb-header-height) + 10px));
}

.post-content [class*='neos-nodetypes-'],
.post-content .carousel {
	margin-bottom: 2rem;
}

.display-1 {
	font-family: 'LPBambus', sans-serif;
}

.display-4 {
	text-shadow: 0 0 10px rgba(0, 0, 0, 1);
	font-weight: bold;
	line-height: 1.38;
}

.display-4 span {
	box-decoration-break: clone;
	padding: 0 1rem;
	background-color: rgba(255, 255, 255, 0.5);
}

.cursor-pointer {
  cursor: pointer;
}

#shop-link {
	font-size: .875rem;
	font-weight: 700;
}

#shop-link a {
	color: #535353;
	text-decoration: underline;
}

#overlay-menu {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: none;
}

#popular-articles {
	margin-bottom: 3rem;
	margin-top: 3rem;
}

#popular-articles a:hover {
	text-decoration: none;
}

#side {
	padding-top: 11px;
}

#content,
#side,
#side hr {
	margin-bottom: 2rem;
	margin-top: 1rem;
}

#content hr {
	margin-top: 3rem;
	margin-bottom: 3rem;
}

#content .post-content hr {
	margin-top: 2rem;
}

#content .post-content .neos-nodetypes-text + hr {
	margin-top: 3rem;
}

#content a {
	text-decoration: underline;
}

#content .btn,
#content h1 a,
#content h2 a,
#content h3 a,
#content h4 a,
#content h5 a,
#content h6 a,
#content a.card,
#content a .btn {
	text-decoration: none;
}

#side .card-title {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: 0;
}

#mainnav {
	top: 0;
	width: 100%;
	z-index: 1;
}

nav,
footer {
	background-color: $primary;
}

.navbar {
	padding: 0;
}

.nav-item {
	font-size: 1.0625rem;
	padding: .6875rem;
	flex-grow: 1;
	text-align: center;

	.footer-nav & {
		font-size: .875rem;
	}

	&.dropdown {
		padding: 0;
	}
}

.nav-item:hover,
.nav-item:active,
.nav-item.active,
.navbar-dark .search-toggle:hover {
	background-color: #4f7aa8;
}

.nav-link {
	cursor: pointer;

	&.dropdown-toggle {
		padding-top: 1.1875rem;
		padding-bottom: 1.1875rem;
	}
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .dropdown-menu .nav-item.active .nav-link {
	color: $white;
}

.navbar-dark .dropdown-menu .nav-item {
	text-align: left;
	padding: 0;
}

.navbar-dark .dropdown-menu .nav-link {
	color: $body-color;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	white-space: nowrap;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
	margin: 0;
	border: 0;
}

.navbar-toggler {
	border: 0;
	padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;

	&:focus,
	&:active,
	&-icon:focus {
		outline: none;
		box-shadow: none;
		border: 0;
	}

	&.collapsed {
		.top-bar {
			transform: rotate(0);
		}

		.middle-bar {
			opacity: 1;
			filter: alpha(opacity=100);
		}

		.bottom-bar {
			transform: rotate(0);
		}
	}

	.top-bar {
		transform: rotate(45deg);
		transform-origin: 0 100%;
	}

	.middle-bar {
		margin-bottom: .3125rem;
		margin-top: .3125rem;
		opacity: 0;
		filter: alpha(opacity=0);
	}

	.bottom-bar {
		transform: rotate(-45deg);
		transform-origin: 0 0;
	}
}

.toggler-icon {
	width: 1.5rem;
	height: .25rem;
	background-color: $body-color;
	border-radius: .125rem;
	display: block;
	transition: all 0.2s;
}

.search-form {
	position: absolute;
	width: calc(100% / 12);
	right: 0;
	top: 0;
	bottom: 0;
	padding-right: 15px;
	padding-left: 15px;
	display: flex;
	align-items: center;
	background-color: $primary;
	overflow: hidden;
	transition: all ease-in .5s;
	transition-property: width;

	&.active {
		width: 100%;

		form {
			width: inherit;
		}
	}

	form {
		width: 0;
		display: flex;
		align-self: stretch;
		overflow: hidden;
	}

	.form-control {
		background-color: $primary;
		border: 0;
		color: $white;

		&:focus {
			box-shadow: none;
		}

		&::-webkit-input-placeholder {
			color: rgba(255, 255, 255, .7);
		}

		&:-ms-input-placeholder {
			color: rgba(255, 255, 255, .7);
		}

		&::placeholder {
			color: rgba(255, 255, 255, .7);
		}
	}
}

.search-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	@include media-breakpoint-down(lg) {
		font-size: 1.25rem;
	}

	.navbar-dark & {
		align-self: stretch;
		min-width: calc(100% / 12);
		cursor: pointer;
		color: $white;
		background-color: $primary;
		position: relative;
		z-index: 3;
	}

	&.active {
		i {
			display: none;
		}
	}
}

#search-toggle-mobile {
	cursor: pointer;
}

.searchcompletion {
	ul {
		margin-bottom: 0;

		+ ul {
			border-top: 1px solid $body-color;

			@include media-breakpoint-up(lg) {
				border-color: $white;
			}
		}
	}

	li {
		font-size: 1.0625rem;
		padding-bottom: .6875rem;
		padding-top: .6875rem;
	}

	img {
		margin-right: 1rem;
	}

	&-suggest {
		align-items: flex-start;

		@include media-breakpoint-up(lg) {
			align-items: center;
		}
	}

	&-wrapper {
		@include media-breakpoint-up(lg) {
			position: absolute;
			width: 100%;
			background-color: $white;
			z-index: 3;
		}

		.autocomplete,
		.suggest {
			margin-top: $spacer * 1.5;
			margin-bottom: $spacer * 1.5;
		}

		.autocomplete {
			padding-right: $spacer * 3;
			border-right: $border-width solid $border-color;
			min-width: 25%;

			li {
				cursor: pointer;
			}
		}
	}
}

.btn {
	font-weight: 700;
	text-transform: uppercase;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		color: #aaa;
	}

	a {
		color: #535353;
		display: block;
		min-height: 26px;
	}
}

.share-buttons {
	> * {
		height: 2.875rem;
	}

	a,
	button {
		color: $body-color;
		font-size: $input-btn-font-size-lg;
	}
}

.shariff-button {
	margin: calc(-1 * $input-btn-padding-y-lg) calc(-1 * $input-btn-padding-x-lg);

	a {
		padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
	}
}

.btn-primary:hover {
	background-color: #4f7aa8;
	border-color: #4f7aa8;
}

#load-newposts-button {
	&:hover {
		background-color: $primary;
		border-color: $primary;
	}

	&:focus {
		box-shadow: none;
	}
}

.rounded-circle {
	transition: border-color .75s ease;

	&.border {
		a:hover & {
			border-color: $blue !important;
		}
	}
}

.fb-checkbox {
	border: 1px solid #CDCDCD;
	width: 20px;
	height: 20px;
	position: relative;

	&__checked {
		&:before {
			content: "\f00c";
			color: #619D2C;
			font-family: "Font Awesome 6 Pro";
			position: absolute;
			top: -2px;
			left: 2px;
			font-size: 22px;
			line-height: 1;

			.card-checkboxes & {
				color: $body-color;
			}
		}
	}

	input {
		display: none;
	}
}

.checklist-download {
	display: grid;
	grid-template-columns: auto min-content;

	@include media-breakpoint-down(lg) {
		&--title {
			grid-column: 1 / span 2;
		}
	}

	@include media-breakpoint-up(lg) {
		&--qrcode {
			grid-row: 1 / span 2;
			grid-column: 2;
		}
	}

	&--buttons {
		button,
		a {
			color: $body-color;
			background-color: $white;
		}
	}
}

.checklist-paperclip {
	position: absolute;
	top: -6px;
	right: $grid-gutter-width;
}

.family-wrapper {
	background-color: #dbdbdb;
}

.family-wrapper .rounded-circle {
	border-color: #fff;
}

.family-wrapper .card-title,
.family-wrapper .card-text {
	font-style: italic;
}

.hover {
	overflow: hidden;

	img {
		transform-origin: center;
		transition: transform .3s ease;
	}

	&:hover img {
		transform: scale(1.05);
	}
}

.blog-item__big {
	position: relative;
	display: block;

	img {
		aspect-ratio: 2/1;

		@include media-breakpoint-down(md) {
			aspect-ratio: 3/2;
		}
	}

	.blog-item {
		&-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 50%;
			text-shadow: 0 0 10px $black;

			* {
				color: $white;
			}
		}

		&-labels {
			position: absolute;
			top: 40px;
			left: 0;

			@include media-breakpoint-down(md) {
				top: 1rem;
			}

			img {
				transform: none;
			}
		}

		&-label {
			font-size: 1rem;
			padding: .5rem .75rem .5rem .5rem;
		}
	}

	.copyright-image {
		position: absolute;
		bottom: 7px;
		right: 80px;
		color: #fff;
		text-shadow: 0 0 5px rgba(0, 0, 0, 1);
	}
}

.card-wrapper {
	padding-bottom: 1.875rem;
}

.card-wrapper .card-title {
	font-weight: bold;
}

.card {
	border: 0;
}

.card:hover {
	text-decoration: none;
}

.card-image-top {
	position: relative;
	min-height: 1px;
}

.most-readed-image {
	min-height: 1px;
}

.preview-labels {
	position: absolute;
	top: 1rem;
	left: 0;
}

.gastbeitrag-label {
	background-color: $primary;
	color: $white;
	padding: 7px 12px;
	font-size: .875rem;
	font-style: italic;
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
}

.gastbeitrag-btn {
	font-size: 1rem;
	padding: .5rem .75rem .5rem .5rem;
}

.card-title {
	color: #535353;
}

.card-title a:hover {
	text-decoration: none;
}

.card-subtitle {
	font-size: 0.9375rem;
	font-weight: normal;
}

.g-0 .card-body {
	padding: 0 1.25rem;
}

.date-short {
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: $yellow;
	color: $white;
	padding: 9px 14px;
	line-height: 1;
	text-align: center;

	&__current {
		background-color: $primary;
	}

	.day {
		font-size: 1.5625rem;
	}
}


.date-update {
	background-color: #dbdbdb;
	color: #7d7e7d;
}

.tag-cloud {
	display: flex;
	flex-wrap: wrap;
}

.tag-item {
	border: 1px solid #ddd;
	padding: 5px 10px;
	margin-bottom: 3px;
	margin-right: 3px;
}

#like-button-wrapper .btn {
	line-height: 1;
	height: 2.875rem;
}

.heart {
	background-image: url('../Images/icon_heartoutline.svg');
	background-repeat: no-repeat;
	display: inline-block;
	width: 2rem;
	height: 2rem;

	&-full {
		background-image: url('../Images/icon_heartsolid.svg');
	}
}


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}

/* Table */
p + .table {
	margin-top: -1rem;
}

table {
	width: 100%;
}

.table th,
.table td {
	border: 2px solid $gray-300;
	/* width: 50%; */
}

.table {
	overflow-x: scroll;
}

.blockquote {
	padding: 0 4rem;

	&-icon {
		font-size: $font-size-base;

		&__image {
			flex: 0 0 3rem;
			max-width: 3rem;

			@include media-breakpoint-down(md) {
				flex: 0 0 4rem;
				min-width: 4rem;
			}
		}
	}
}

.hinttip {
	position: relative;

	ul {
		padding-left: 1.25rem;
	}

	&-icon {
		flex: 0 0 2rem;
		width: 2rem;
	}

	&-title {
		font-size: $h4-font-size;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 0;
	}
}

/* Scroll to top */
#scroll-to-top,
#pushnotification-button {
  position: fixed;
  color: $gray-300;
  background-color: $white;
  font-size: 24px;
  opacity: 0;
  width: 55px;
  height: 55px;
  overflow: hidden;
  transition: all .5s ease;
  transition-property: opacity, bottom, color, border-color;
  z-index: -1;

	&:hover {
		color: $blue;
		border-color: $blue !important;
	}

	&.show {
		opacity: 1;
		cursor: pointer;
		z-index: 10;
	}

	> * {
		margin-top: -2px;
	}
}

#scroll-to-top {
	right: 20px;
}

#pushnotification-button {
	left: 20px;
	bottom: 20px;
}

.toast {
	position: fixed;
	top: 85px;
	right: 20px;
	border-radius: 0;
	z-index: -1;
}

.toast.show {
	z-index: 0;
}

.offcanvas-footer {
	text-align: center;
	padding: $offcanvas-padding-y $offcanvas-padding-x;
}

.basis-100 {
	flex-basis: 100%;
}

/* Lexikon */
.lexika-groups {
	display: flex;
	justify-content: stretch;
	list-style: none;
	padding: 0;
	margin-left: -5px;
	margin-right: -5px;
}

.lexika-group {
	padding-left: 5px;
	padding-right: 5px;
	flex: 1 1 100%;
	display: flex;
}

.lexika-group-link {
	flex: 1 1 100%;
	padding: 0.1875rem .5rem;
	font-size: 1.6875rem;
}

.lexika-content-wrapper {
	column-count: 3;
	column-gap: 30px;
}

.lexika-group-title {
	font-size: 2.75rem;
	font-weight: bold;
	text-transform: uppercase;
	background-color: #004481;
	color: #ffffff;
	display: inline-block;
	padding: 0 1rem;
}

.lexika-item-wrapper {
	break-inside: avoid;
}

.lexika-list {
	list-style: none;
	padding: 0 0 0 2rem;
}

.lexika-list li {
	position: relative;
	margin-bottom: 1rem;
}

.lexika-list li::before {
	font-family: 'Font Awesome 6 Pro';
	content: '\f054';
	position: absolute;
	left: -2rem;
}

.breadcrumb-item + .breadcrumb-item::before {
	display: inline;
}

/*Pagination*/
.neos-widget-paginator {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0;
}

.neos-widget-paginator li a,
.neos-widget-paginator li.current {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -2px;
	line-height: 1.25;
	color: #004481;
	background-color: #fff;
	border: 2px solid #004481;
	text-decoration: none !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.neos-widget-paginator li a:hover {
	z-index: 2;
	text-decoration: none;
	background-color: #004481;
	color: #ffffff;
}

.neos-widget-paginator li.current {
	z-index: 1;
	color: #fff;
	background-color: #004481;
}

/* YouTube-Videos */
.neos-nodetypes-youtube {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;

	iframe {
		height: 100%;
		position: absolute;
		width: 100%;
	}
}

.neos-nodetypes-youtube-preview {
	position: relative;
	color: $white;
	cursor: pointer;

	&:hover .play-button {
		border-color: $primary;
		color: $primary;
	}

	.play-button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100px;
		height: 100px;
		border-color: #fff;
		transition: all .75s ease;

		i {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-38%, -50%);
			line-height: 1;
			font-size: 70px;
		}
	}

	.youtube-title {
		position: absolute;
		top: 10px;
		left: 1rem;
		font-size: 1.25rem;
	}
}

/* Advantage / Disadvantage */
.advantage,
.disadvantage {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	i {
		font-size: 2.5rem;
		margin-right: 1rem;
	}
}

.advantage i {
	color: #619d2c;

	&::before {
		content: unicode("f055");
	}
}

.disadvantage i {
	color: #f7b106;

	&::before {
		content: unicode("f056");
	}
}

.advantage p,
.disadvantage p {
	margin-bottom: 0;
}

/* Gliederung */
.gliederung {
	background-color: $gray-200;
}

.gliederung ul {
	list-style: none;
	margin-bottom: 0;
	padding-left: 1.25rem;
}

.gliederung li {
	position: relative;
	margin-bottom: 1rem;
}

.gliederung li:last-child {
	margin-bottom: 0;
}

.gliederung li::before {
	position: absolute;
	content: '\f054';
	font-family: 'Font Awesome 6 Pro';
	left: -1.25rem;
	top: 2px;
}

.gliederung .level-3 {
	margin-left: 1.3rem;
}

.author-description {
	flex: 1 1 auto;
}

.authorbox {
	position: relative;
	padding-bottom: 3.75rem;
	padding-top: 3.75rem;
}

.authorbox::before {
	content: '';
	position: absolute;
	top: -15px;
	left: 100px;
	width: 0;
	height: 0;
	border: 0 solid transparent;
	border-right-width: 20px;
	border-left-width: 20px;
	border-bottom: 15px solid $gray-200;
}

.authorbox svg {
	fill: $primary;
}

.authorpreview {
	&__name {
		min-width: 150px;
		margin-top: -25px;
		text-transform: inherit;
		padding: 0.5rem;
	}
}

#content .authorbox hr {
	border-top: 2px solid;
	color: $white;
	margin-top: 2rem;
	margin-bottom: 2rem;
	opacity: 1;
}

.authorbox-title {
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 0;
}

.svg-icon {
	display: flex;
	justify-content: center;
}

.svg-icon svg {
	height: 150px;
}

.svg-icon-small svg {
	height: 60px;
}

.svg-icon-small.three-words-icon svg {
	padding-top: 5px;
	padding-bottom: 5px;
}

/* Social Media */
.footer-social-media__header {
	font-size: 1.0625rem;
	font-style: italic;
	font-weight: bold;
	color: $white;
	margin-bottom: 0;
}

.footer-social-links {
	display: flex;
	align-items: center;
	height: 100%;
}

.social-media-icon {
	display: flex;
	width: 100%;
	max-width: 3rem;
	height: 2rem;
	margin: 0.4375rem 1.75rem 1.5rem 0;
}

.social-media-icon svg {
	fill: #FFFFFF;
	height: 100%;
}

.footer-nav .navbar-nav li:first-child a {
	padding-left: 0;
}

.brand-logo {
	max-height: 63px;

	@include media-breakpoint-down(lg) {
		max-height: 35px;
	}
}

.img-placeholder-container {
	width: 100%;
	padding-top: 66.6%;
}

.input-group {
	> .btn {
		border: 0;
		border-bottom: $input-border-width solid $input-border-color;

		&:focus {
			box-shadow: none;
		}
	}
}

.form-control {
	border: 0;
	border-bottom: $input-border-width solid $input-border-color;

	&:focus {
		box-shadow: none;
	}
}

#button-search {
	border-color: $primary;
}

#button-search-clear {
	font-size: .75rem;
	color: $gray-200;
	width: 0;

	&.active {
		color: $gray-650;
		width: auto;
	}
}

@media (max-width: 991px) {
	.navbar {
		padding: .5rem 0;
	}

	.navbar-wrapper {
		position: static;
	}

	.search-form {
		width: calc(100% / 12 * 3);
		padding-left: 0;
		padding-right: 0;
	}

	#resNav {
		background-color: $white;
		position: fixed;
		top: var(--fb-header-height);
		right: 100%;
		width: 100%;
		height: calc(100vh - var(--fb-header-height));
		z-index: 11;
		transition: right .25s ease-in;

		&.show {
			right: 0;
		}

		.nav-item.active,
		.nav-item.active a,
		.nav-item.active .nav-link,
		.nav-item:active,
		.nav-item:active a,
		.nav-item:active .nav-link,
		.nav-item:hover,
		.nav-item:hover a,
		.nav-item:hover .nav-link, {
			color: #fff;
			margin-left: -1rem;
			margin-right: -1rem;
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.nav-link {
			display: flex;
			justify-content: space-between;
			color: $body-color;

			&::after {
				content: '>';
				width: calc(25% - 20px);
				text-align: center;
			}
		}

		hr {
			margin-bottom: 0;
			margin-top: 0;
		}

		.menu-level-1 {
			display: none;
		}

		.menu-level-link-back {
			cursor: pointer;
		}
	}

	#resSearch {
		background-color: $white;
		position: fixed;
		top: 0;
		left: 100%;
		width: 100%;
		height: 100vh;
		z-index: 11;
		transition: left .25s ease-in;

		&.show {
			left: 0;
		}
	}

	.lexika-groups {
		flex-wrap: wrap;
	}

	.lexika-group {
		flex: 1 1 60px;
		margin-bottom: 1rem;
	}

	.lexika-content-wrapper {
		column-count: 2;
	}

	.nav-item {
		text-align: left;
		padding-left: 0;
		padding-right: 0;
	}

	.nav-link {
		padding-bottom: 0.125rem;
		padding-top: 0.125rem;
	}

	.authorbox {
		padding-bottom: 2rem;
		padding-top: 2rem;
	}
}

@media (max-width: 767px) {
	.display-1 {
		font-size: 3.5rem;
	}
	.display-4 {
		font-size: 1.5rem;
	}

	.btn-lg,
	.btn-group-lg > .btn {
		font-size: 0.875rem;
		padding: .5rem 1.5rem;
	}

	#side {
		padding-top: 0;
	}

	.blog-item__big {
		margin-left: -0.75rem;
		margin-right: -0.75rem;

		.blog-item {
			&-content {
				width: 100%;
				position: static;
				transform: inherit;
				text-shadow: none;

				* {
					color: $primary;
				}
			}

			&-label {
				top: 2rem;
				font-size: 1rem;
				padding: .25rem .5rem;
			}
		}
	}

	.blockquote {
		padding: 0 2rem;
	}

	.footer-nav .navbar-nav {
		width: 100%;
	}

	#scroll-to-top {
		min-width: 50px;
		min-height: 50px;
		bottom: 160px;
	}

	.lexika-content-wrapper {
		column-count: 1;
	}

	#popular-articles {
		margin-top: 1.5rem;
	}

	#popular-articles h2 {
		font-size: 1.5rem;
	}

	#content.post {
		margin-top: 0;
	}
	.authorbox::before {
		left: 50%;
		transform: translateX(-50%);
	}
}

@include media-breakpoint-down(md) {
	.sl-wrapper .sl-image .sl-caption {
		position: static;
	}
}

@media (min-width: 768px) {
	.post-content [class*="neos-nodetypes-"] [class*="neos-nodetypes-"] {
		margin-bottom: 0;
	}
	.post-content [class*="neos-nodetypes-"] [class*="neos-nodetypes-"] figure {
		margin-bottom: 0;
	}
}

@media (min-width: 992px) {
	.border-right-lg {
		border-right-width: 2px;
		border-right-style: solid;
	}
}

// SimpleLightbox
.sl-spinner {
	border: 5px solid #fff;
}

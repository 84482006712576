%offcanvas-borderradius {
	border-top-left-radius: .75rem;
	border-top-right-radius: .75rem;
}

%offcanvas-large {
	&.offcanvas-top {
		bottom: inherit;
		margin-left: auto;
		margin-right: auto;
	}

	@include media-breakpoint-up(lg) {
		max-width: 600px;
		border-radius: 0 0 .75rem .75rem;
		flex-direction: row;
	}
}

#offcanvas-notification {
	@extend %offcanvas-borderradius;

	@include media-breakpoint-up(lg) {
		max-width: 500px;
		left: 20px;
		bottom: 20px;
		border-bottom-left-radius: .75rem;
		border-bottom-right-radius: .75rem;
	}
}

#pushcategories-offcanvas {
	@extend %offcanvas-large;
	@extend %offcanvas-borderradius;
}

#pushnotification-offcanvas {
	@extend %offcanvas-large;
	@extend %offcanvas-borderradius;
}

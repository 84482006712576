$gray-100: #f6f6f6;
$gray-200: #ededed;
$gray-300: #dbdbdb;
$gray-400: #cdcdcd;
$gray-650: #999999;
$gray-750: #535353;
$blue: #004481;
$yellow: #f7b106;

$secondary: $gray-400;
$light: $gray-200;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
) !default;

$body-color: $gray-750;
$border-color: $gray-400;

$body-text-align: left;

$font-size-base: .9375rem;
$font-size-sm: $font-size-base * .875 !default;
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.375rem;
$h4-font-size: 1.125rem;

$nav-link-transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out;
$navbar-toggler-padding-y: 0;
$navbar-toggler-focus-width: 0;

$link-decoration: none;

$btn-padding-y: .75rem;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$input-bg: transparent;
$input-border-radius: 0;
$input-focus-border-color: $gray-400;
$input-placeholder-color: $gray-400;

$dropdown-border-radius: 0;

$breadcrumb-font-size: $font-size-sm;

$card-spacer-x: 0;
$card-border-radius: 0;

$popover-border-radius: 0;

$offcanvas-vertical-height: auto;
